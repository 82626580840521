<script>
import AssetGroupService from "@/services/AssetGroupService";
import AssetTypeService from "@/services/AssetTypeService";
import { pick } from "lodash";

export default {
    name: "VAssetGroupForm",
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            },
        },
    },
    data() {
        return {
            editing: false,
            loading: false,
            errors: null,
            assetTypes: [],
            assetGroup: {
                id: null,
                asset_type_id: null,
                name: null,
            },
        };
    },
    created() {
        if (!this.context.empty) {
            this.editing = true;
            this.assetGroup = pick(this.context, [
                "id",
                "asset_type_id",
                "name",
            ]);
        }

        this.fetchTypes();
    },
    methods: {
        async saveForm() {
            try {
                if (this.editing) {
                    const r = await AssetGroupService.PUT(this.assetGroup);
                } else {
                    const r = await AssetGroupService.POST(this.assetGroup);
                }
                this.$emit("close-refresh");
            } catch (e) {
                if (typeof e.response === "undefined") {
                    this.$swal({
                        icon: "error",
                        title: this.$t("error_cannot_save"),
                        text: this.$t("error_cannot_save_explain"),
                    });
                } else {
                    this.errors = e.response.data.errors;
                }
            }
        },

        async deleteEntity() {
            if (this.editing) {
                this.$swal({
                    icon: "warning",
                    text: this.$t("assetGroup.delete_are_you_sure"),
                    showConfirmButton: true,
                    showCancelButton: true,                     
                }).then(async (confirmed) => {                    
                    if (confirmed.isConfirmed) {
                        try {
                            const r = await AssetGroupService.DELETE(
                                this.assetGroup.id
                            );

                            this.$emit("close-refresh");
                        } catch (e) {
                            if ("errors" in e.response.data) {
                                this.$swal({
                                    icon: "error",
                                    title: this.$t("error_cannot_delete"),
                                    text: e.response.data.errors.join(".\n"),
                                });
                            } else {
                                this.$swal({
                                    icon: "error",
                                    title: this.$t("error_cannot_delete"),
                                    text: this.$t("unknown_error"),
                                });
                            }
                        }
                    }
                });
            }
        },

        async fetchTypes() {
            try {
                this.loading = true;
                const r = await AssetTypeService.dropdown();
                this.assetTypes = r.data.data;
            } catch (e) {}

            this.loading = false;
        },
    },
};
</script>

<template>
    <modal size="lg" :show="true" @close="$emit('close')" class="asset-modal">
        <template slot="header">
            <template v-if="editing">
                {{ $t("assetGroup.edit_group") }}
            </template>
            <template v-else>
                {{ $t("assetGroup.create_group") }}
            </template>
        </template>

        <template slot="default">
            <FormulateForm
                v-model="assetGroup"
                @submit="saveForm"
                :errors="errors"
            >
                <div class="row">
                    <div class="col">
                        <v-loader :loading="loading" v-if="loading" />
                        <FormulateInput
                            v-else-if="assetTypes"
                            name="asset_type_id"
                            type="select"
                            :label="$t('assetGroup.asset_type')"
                            :validation-name="$t('assetGroup.asset_type')"
                            :options="assetTypes"
                            :placeholder="$t('assetGroup.select_asset_type')"
                            validation="required"
                        />
                    </div>
                    <div class="col">
                        <FormulateInput
                            name="name"
                            type="text"
                            :label="$t('assetGroup.name')"
                            :validation-name="$t('assetGroup.name')"
                            validation="required"
                        />
                    </div>
                </div>

                <div class="text-right mt-4">
                    <div class="row">
                        <div class="col text-left">
                            <base-button
                                v-if="editing"
                                type="danger"
                                :outline="true"
                                @click="deleteEntity()"
                                ><i class="far fa-trash"></i
                            ></base-button>
                        </div>
                        <div class="col text-right">
                            <base-button
                                type="secondary"
                                :outline="true"
                                @click="$emit('close')"
                                >{{ $t("cancel") }}</base-button
                            >
                            <base-button
                                type="primary"
                                :loading="loading"
                                native-type="submit"
                                >{{ $t("save") }}</base-button
                            >
                        </div>
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>