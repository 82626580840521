<script>
import VFilters from "@/components/Search/VFilters";
import ContractService from "@/services/ContractService";
import CustomerService from "@/services/CustomerService";
import AssetTypeService from "@/services/AssetTypeService";
import MGConsts from "@/mixins/MGConsts";

export default {
    name: "VIndex",
    components: {
        VFilters
    },
    mixins: [MGConsts],
    data() {
        return {
            loading: false,
            contracts: [],
            meta: {},
            customers: [],
            asset_types: []
        };
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CONTRACT_MGMT);
        this.apiSearchFilter = this.initSearch();
        this.$apiEnableWatcher();
        await this.fetchCustomers(true);
        await this.fetchAssetTypes(true);
        await this.fetchData();
    },
    methods: {
        /**
         * Search initialization
         */
        initSearch() {
            return {
                search: "",
                customer_id: "",
                asset_type_id: "",
                contract_type: ""
            };
        },
        /**
         * Reset search event (searchi filters)
         */
        resetSearch() {
            this.$resetSearch();
            this.apiSearchFilter = this.initSearch();
        },
        /**
         * Fetch all contracts -> as per search plugin ;)
         */
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                const r = await ContractService.GET(params);
                this.contracts = r.data.data;
                this.meta = r.data.meta;
            } catch (e) {}
            this.loading = false;
        },
        /**
         * Fetch dropdown data
         */
        async fetchCustomers(loading = false) {
            try {
                this.loading = true;
                const r = await CustomerService.dropdown();
                this.customers = r.data.data;
            } catch (e) {}

            this.loading = loading;
        },
        /**
         * Fetch asset types
         */
        async fetchAssetTypes(loading = false) {
            try {
                this.loading = true;
                const r = await AssetTypeService.dropdown();
                this.asset_types = r.data.data;
            } catch (e) {}

            this.loading = loading;
        }
    }
};
</script>

<template>
    <div class="v-contract-index">
        <base-header class="pb-1" type="">
            <bread-bag> </bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-filters
                        :enable-filters="true"
                        @reset-search="resetSearch"
                        :search="true"
                        v-model="apiSearchFilter.search"
                    >
                        <template slot="default">
                            <div class="col-lg-4 col-md-12">
                                <FormulateInput
                                    v-model="apiSearchFilter.customer_id"
                                    :placeholder="$t('contracts.customer')"
                                    type="select"
                                    :options="customers"
                                />
                            </div>
                            <div class="col-lg-4 col-md-5 col-sm-12">
                                <FormulateInput
                                    v-model="apiSearchFilter.asset_type_id"
                                    :placeholder="
                                        $t('contracts.asset_contract_type')
                                    "
                                    type="select"
                                    :options="asset_types"
                                />
                            </div>

                            <div class="col-lg-4 col-md-5 col-sm-12">
                                <FormulateInput
                                    v-model="apiSearchFilter.contract_type"
                                    :placeholder="$t('contracts.contract_type')"
                                    type="select"
                                    :options="mgContractType"
                                />
                            </div>
                        </template>
                    </v-filters>

                    <card body-classes="px-0">
                        <v-loader :loading="loading" v-if="loading" />
                        <!-- table -->
                        <template v-else-if="contracts.length">
                            <div class="table-responsive">
                                <v-table class="table table-sm table-mg">
                                    <v-thead>
                                        <v-tr>
                                            <v-th>
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Customers.name"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("contracts.customer")
                                                    }}</v-sorter
                                                >
                                            </v-th>
                                            <v-th>
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Contracts.id"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "contracts.contract_id"
                                                        )
                                                    }}</v-sorter
                                                >
                                            </v-th>
                                            <v-th>
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Contracts.asset_type_id"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "contracts.contract_asset_type"
                                                        )
                                                    }}</v-sorter
                                                >
                                            </v-th>
                                            <v-th>
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Contracts.contract_type"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("contracts.type")
                                                    }}</v-sorter
                                                >
                                            </v-th>
                                            <v-th>
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Contracts.from_date"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "contracts.contract_dates_from"
                                                        )
                                                    }}</v-sorter
                                                >
                                            </v-th>
                                            <v-th>
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Contracts.to_date"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "contracts.contract_dates_to"
                                                        )
                                                    }}</v-sorter
                                                >
                                            </v-th>
                                            <v-th>
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    name="Contracts.termination_date"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "contracts.termination_date"
                                                        )
                                                    }}</v-sorter
                                                >
                                            </v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr
                                            v-for="c in contracts"
                                            :key="c.id"
                                        >
                                            <v-td>
                                                <router-link
                                                    :to="{
                                                        name:
                                                            'CustomersTabManageContract',
                                                        params: {
                                                            customer_id:
                                                                c.customer.id,
                                                            contract_id: c.id
                                                        }
                                                    }"
                                                    >{{
                                                        c.customer.name
                                                    }}</router-link
                                                >
                                                <div class="small mt-1">
                                                    <router-link
                                                        class="text-muted"
                                                        :to="{
                                                            name:
                                                                'CustomersTabContracts',
                                                            params: {
                                                                customer_id:
                                                                    c.customer
                                                                        .id
                                                            }
                                                        }"
                                                        >{{
                                                            $t(
                                                                "contracts.show_all_contracts_for_this_customer"
                                                            )
                                                        }}</router-link
                                                    >
                                                </div>
                                            </v-td>
                                            <v-td
                                                >{{ c.id }}
                                                <div class="small text-muted">
                                                    {{
                                                        $t(
                                                            "contracts.rev_short"
                                                        )
                                                    }}:
                                                    {{ c.contract_version }}
                                                </div>
                                            </v-td>
                                            <v-td
                                                ><i
                                                    :class="
                                                        `far ${c.asset_type.icon}`
                                                    "
                                                ></i
                                            ></v-td>
                                            <v-td>{{
                                                c.contract_type_text
                                            }}</v-td>
                                            <v-td>
                                                {{
                                                    $d(
                                                        new Date(c.from_date),
                                                        "short"
                                                    )
                                                }}
                                            </v-td>
                                            <v-td>
                                                <template v-if="c.to_date"
                                                    >{{
                                                        $d(
                                                            new Date(c.to_date),
                                                            "short"
                                                        )
                                                    }}
                                                </template>

                                                <template v-else
                                                    ><i
                                                        class="fal fa-infinity"
                                                    ></i
                                                ></template>
                                            </v-td>
                                            <v-td>
                                                <template
                                                    v-if="c.termination_date"
                                                >
                                                    <span class="text-danger">
                                                        {{
                                                            $d(
                                                                new Date(
                                                                    c.termination_date
                                                                ),
                                                                "short"
                                                            )
                                                        }}
                                                    </span>
                                                    <div
                                                        v-if="
                                                            c.termination_note
                                                        "
                                                        class="small"
                                                    >
                                                        {{ c.termination_note }}
                                                    </div>
                                                </template>
                                                <span
                                                    v-else
                                                    class="text-success"
                                                >
                                                    <i class="far fa-check"></i>
                                                </span>
                                            </v-td>
                                            <v-td v-if="false">{{ c }}</v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                            <div class="paginator">
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                        </template>

                        <div
                            class="alert alert-warning alert-outline ml-4 mr-4"
                            v-else
                        >
                            {{ $t("contracts.no_contracts_found") }}
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<style></style>
