<script>
import ContractService from "@/services/ContractService";
import CustomerService from "@/services/CustomerService";
import AssetTypeService from "@/services/AssetTypeService";
import MGConsts from "@/mixins/MGConsts";

export default {
    name: "VTerminations",

    mixins: [MGConsts],
    data() {
        return {
            loading: false,
            grouped: [],
        };
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CONTRACT_MGMT);
        await this.fetchData();
    },
    methods: {
        /**
         * Fetch all contracts -> as per search plugin ;)
         */
        async fetchData() {
            try {
                this.loading = true;
                const r = await ContractService.GET_terminations();
                this.grouped = r.data.data;
            } catch (e) {
                console.log(e);
            }
            this.loading = false;
        },
    },
};
</script>

<template>
    <div class="v-contract-index">
        <base-header class="pb-1" type="">
            <bread-bag> </bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <v-loader :loading="loading" v-if="loading" />
                <template v-else-if="grouped">
                    <div
                        class="col-12"
                        v-for="(contracts, termination_month, index) of grouped"
                        :key="termination_month"
                    >
                        <card body-classes="px-0">
                            <!-- table -->

                            <div>
                                <h3 class="px-4 mb-4">
                                    {{
                                        $t(
                                            "contracts.possible_terminations_in_month_MM_YYYY"
                                        )
                                    }}:
                                    {{
                                        $d(
                                            new Date(termination_month),
                                            "longMonthOnly"
                                        )
                                    }}
                                </h3>
                                <div class="table-responsive">
                                    <v-table class="table table-sm table-mg">
                                        <v-thead>
                                            <v-tr>
                                                <v-th>{{
                                                    $t("contracts.customer")
                                                }}</v-th>
                                                <v-th>{{
                                                    $t("contracts.contract_id")
                                                }}</v-th>
                                                <v-th>{{
                                                    $t(
                                                        "contracts.contract_asset_type"
                                                    )
                                                }}</v-th>
                                                <v-th>{{
                                                    $t(
                                                        "contracts.contract_type"
                                                    )
                                                }}</v-th>
                                                <v-th>{{
                                                    $t(
                                                        "contracts.next_possible_termination_date"
                                                    )
                                                }}</v-th>
                                            </v-tr>
                                        </v-thead>
                                        <v-tbody>
                                            <v-tr
                                                v-for="c in contracts"
                                                :key="c.id"
                                            >
                                                <v-td style="width: 40%">
                                                    <router-link
                                                        :to="{
                                                            name:
                                                                'CustomersTabContracts',
                                                            params: {
                                                                customer_id:
                                                                    c.customer
                                                                        .id,
                                                            },
                                                        }"
                                                        >{{
                                                            c.customer.name
                                                        }}</router-link
                                                    >
                                                </v-td>
                                                <v-td
                                                    >{{ c.contract_id }}
                                                    <div
                                                        class="small text-muted"
                                                    >
                                                        {{
                                                            $t(
                                                                "contracts.rev_short"
                                                            )
                                                        }}:
                                                        {{ c.contract_version }}
                                                    </div>
                                                </v-td>
                                                <v-td
                                                    ><i
                                                        class="far"
                                                        :class="`${c.asset_type_icon}`"
                                                    ></i
                                                ></v-td>
                                                <v-td>{{
                                                    c.contract_type_text
                                                }}</v-td>

                                                <v-td>
                                                    {{
                                                        $d(
                                                            new Date(
                                                                c.next_termination_date
                                                            ),
                                                            "short"
                                                        )
                                                    }}
                                                </v-td>
                                                <v-td v-if="false">{{
                                                    c
                                                }}</v-td>
                                            </v-tr>
                                        </v-tbody>
                                    </v-table>
                                </div>
                            </div>
                        </card>
                    </div>
                </template>
                <div class="col-12" v-else>
                    <div class="alert alert-warning alert-outline ml-4 mr-4">
                        {{ $t("contracts.no_contracts_found") }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style>
</style>