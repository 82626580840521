import Http from "@/libraries/Http"; 

export default {
    async getNetPrices(params = {}) {
        return Http.get("/v1/net-price-indices", {params: params}); 
    }, 

    async postNetPrice(data) {
        return Http.post("/v1/net-price-indices", data);
    },

    async putNetPrice(data) {        
        return Http.put(`/v1/net-price-indices/${data.id}`, data);
    }, 

    async deleteNetPrice(id) {
        return Http.delete(`/v1/net-price-indices/${id}`); 
    }
    
}