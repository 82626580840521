<script>
import AssetTypeService from "@/services/AssetTypeService";

import { pick } from "lodash";

export default {
    name: "VAssetTypeForm",
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            },
        },
        ec_products: {            
            description: "Economic products",
        },
    },
    data() {
        return {
            loading: false,
            errors: null,
            setting: null,
            atype: {
                id: null,
                economic_handle: null,                                
            },
        };
    },
    created() {        
        this.atype = pick(this.context, [
            "id",
            "economic_handle"
        ]);
    },
    methods: {
        /** 
         * Save form
         */
        async saveForm() {
            try {         
                this.loading = true;        
                const r = await AssetTypeService.PUT(this.atype);                
                this.$emit("close-refresh");
            } catch (e) {
                console.log(e);
                this.errors = e.response.data.errors;
            }
            this.loading = false; 
        },        
    },
};
</script>

<template>
    <modal
        size="lg"
        :show="true"
        @close="$emit('close')"
        class="asset-type-modal"
    >
        <template slot="header">
            {{ $t("asset_type.edit_asset") }}
        </template>

        <template slot="default">
            
            <FormulateForm
                v-model="atype"
                @submit="saveForm"
                :errors="errors"            
            >
            <div class="row">
                <div class="col">
                    <FormulateInput
                            name="economic_handle"
                            type="select"
                            :options="ec_products"
                            :label="$t('asset_type.economic_product')"
                            :validation-name="$t('asset_type.economic_product')"
                            :placeholder="
                                $t('asset_type.select_economic_product')
                            "
                            validation="required"
                        />
                </div>
            </div>
            <div class="row mt-4">                    
                    <div class="col text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>


<style>
</style>