<script>
export default {
    name: "VSettings",
};
</script>


<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col">
                    <v-wip title="Settings" />
                </div>
            </div>
        </div>
    </div>
</template>