<script>
import VGlobalStats from "@/components/Momentum/Contracts/Stats/VGlobalStats";
import VStatExport from "@/components/Momentum/Contracts/Stats/VStatExport";
import VContractStats from "@/components/Momentum/Contracts/Stats/VContractStats";
import VContractDetails from "@/components/Momentum/Contracts/Stats/VContractDetails";
import VAssets from "@/components/Momentum/Contracts/Stats/VAssets";
import VAssetsDetails from "@/components/Momentum/Contracts/Stats/VAssetsDetails";
import VProductionTurnover from "@/components/Momentum/Contracts/Stats/VProductionTurnover";

import CurrencyService from "@/services/CurrencyService";
import ContractService from "@/services/ContractService";

export default {
    name: "VReports",

    components: {
        VGlobalStats,
        VAssets,
        VProductionTurnover,
        VAssetsDetails,
        VContractStats,
        VContractDetails,
        VStatExport,
    },
    data() {
        return {
            loading: false,
            loadingCS: true,
            loadingCD: true,
            cs: null,
            cd: null,
            currency: "DKK",
            currencies: [],
        };
    },
    watch: {
        currency: function () {
            this.loadReports();
        },
    },
    async created() {
        this.loading = true;
        await this.fetchCurrencies();
        await this.loadReports();
        this.loading = false;
    },
    methods: {
        async loadReports() {
            this.loadingCS = true;
            this.loadingCD = true;
            await this.fetchCS();
            await this.fetchCD();
        },
        /**
         * Get currency list
         */
        async fetchCurrencies() {
            try {
                const r = await CurrencyService.dropdownCodes({
                    fview: "active",
                });
                this.currencies = r.data.data;
            } catch (e) {}
        },
        async fetchCS() {
            try {
                this.loadingCS = true;
                const r = await ContractService.GET_statistics({
                    currency: this.currency,
                });
                this.cs = r.data.data;
            } catch (e) {
                console.log("fetchCS exception", e);
            }

            this.loadingCS = false;
        },
        async fetchCD() {
            try {
                this.loadingCD = true;
                const r = await ContractService.GET_statisticsContractDetails({
                    currency: this.currency,
                });
                this.cd = r.data.data;
            } catch (e) {
                console.log("fetchCD exception", e);
            }

            this.loadingCD = false;
        },
    },
};
</script>


<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12 d-flex">
                    <h1>{{ $t("reports.statistics") }}</h1>
                    <div class="ml-auto">
                        <FormulateInput
                            v-model.lazy="currency"
                            type="select"
                            :options="currencies"
                        />
                    </div>
                </div>
                <!-- GLOBAL STATS -->
                <div class="col-12">
                    <card body-classes="px-0">
                        <h3 class="px-4 mb-4">
                            {{ $t("reports.distribution") }}
                        </h3>
                        <div class="px-4">
                            <v-loader :loading="loadingCS" v-if="loadingCS" />
                            <v-global-stats
                                :stats="cs.assets_stats"
                                :cstats="cs.contracts"
                                v-else
                            />
                        </div>
                    </card>
                </div>

                <!-- CONTRACTS -->
                <div class="col-12">
                    <card body-classes="px-0">
                        <h3 class="px-4 mb-4">
                            {{ $t("reports.contracts") }}
                        </h3>
                        <div class="px-4">
                            <v-loader :loading="loadingCS" v-if="loadingCS" />
                            <v-contract-stats :stats="cs.contracts" v-else />
                        </div>
                    </card>
                </div>
                <!-- CONTRACTS -->

                <!-- CONTRACTS DETAILS -->
                <div class="col-12">
                    <card body-classes="px-0">
                        <h3 class="px-4 mb-4">
                            {{ $t("reports.contract_turnover") }}
                        </h3>
                        <div class="px-4">
                            <v-loader :loading="loadingCD" v-if="loadingCD" />
                            <v-contract-details
                                :stats="cd"
                                :currency="currency"
                                v-else
                            />
                        </div>
                    </card>
                </div>
                <!-- CONTRACTS  DETAILS -->

                <!-- ASSETS -->
                <div class="col-12">
                    <card body-classes="px-0">
                        <h3 class="px-4 mb-4">
                            {{ $t("reports.assets") }}
                        </h3>
                        <div class="px-4">
                            <v-loader :loading="loadingCS" v-if="loadingCS" />
                            <v-assets
                                :stats="cs.assets_stats"
                                :cstats="cs.contracts"
                                v-else
                            />
                        </div>
                    </card>
                </div>
                <!-- .ASSETS -->

                <!-- PRODUCTION -->
                <div class="col-12">
                    <card body-classes="px-0">
                        <h3 class="px-4 mb-4">
                            {{ $t("reports.production_and_turnover") }}
                        </h3>
                        <div class="px-4">
                            <v-loader :loading="loadingCS" v-if="loadingCS" />
                            <v-production-turnover :stats="cs.budgets" v-else />
                        </div>
                    </card>
                </div>
                <!-- .PRODUCTION -->

                <!-- ASSETS GROUPED -->
                <div class="col-12">
                    <card body-classes="px-0">
                        <h3 class="px-4 mb-4">
                            {{ $t("reports.assets_overview") }}
                        </h3>
                        <div class="px-4">
                            <v-loader :loading="loadingCS" v-if="loadingCS" />
                            <v-assets-details
                                :stats="cs.assets_grouped"
                                v-else
                            />
                        </div>
                    </card>
                </div>
                <!-- ASSETS GROUPED -->

                <!-- CSV EXPORT -->
                <div class="col-12">
                    <card body-classes="px-0">
                        <h3 class="px-4 mb-4">
                            {{ $t("reports.data_export") }}
                        </h3>
                        <div class="px-4">
                            <v-stat-export />
                        </div>
                    </card>
                </div>
                <!-- CSV EXPORT -->
            </div>
        </div>
    </div>
</template>