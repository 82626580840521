<script>
import InnerDashCard from "@/components/Cards/InnerDashCard";
import HInnerDashCard from "@/components/Cards/HInnerDashCard";
import CurrencyHumanizer from "@/util/MillionHumanizer";
import NumberHumanizer from "@/util/NumberHumanizer";
import QMath from "@/libraries/QMath";

export default {
    name: "VProductionTurnover",
    props: {
        stats: {
            description: "Assets stats",
        },
    },
    components: { InnerDashCard, HInnerDashCard },
    data() {
        return {
            showKWH: false,
        };
    },
    methods: {
        h(number) {
            return CurrencyHumanizer(number, this.stats.currency);
        },
        ch: CurrencyHumanizer,
        hn: NumberHumanizer,
        perc: QMath.perc,
    },
};
</script>

<template>
    <div>
        <pre v-if="false">{{ stats }}</pre>
        <div class="row">
            <!-- Box: MW from wind -->
            <div class="col-12 col-md-6 col-lg-3">
                <inner-dash-card
                    :title="$t('reports.actual_kwh_year')"
                    icon="far fa-tachometer-fast"
                >
                    <template slot="default">{{
                        stats.current.total_actual_production_kwh | numanize
                    }}</template>
                    <template slot="footer"
                        ><i class="far fa-calendar"></i>
                        {{
                            $t("reports.actual_data_for_Y", {
                                Y: stats.current.actual_year,
                            })
                        }}
                    </template>
                </inner-dash-card>
            </div>
            <!-- Box: MW from solar -->
            <div class="col-12 col-md-6 col-lg-3">
                <inner-dash-card
                    :title="$t('reports.budget_kwh')"
                    icon="far fa-tachometer-fast"
                >
                    <template slot="default">{{
                        stats.current.total_planned_production_kwh | numanize
                    }}</template>
                    <template slot="footer"
                        ><i class="far fa-calendar"></i>
                        {{
                            $t("reports.planned_data_for_Y", {
                                Y: stats.current.planned_year,
                            })
                        }}
                    </template>
                </inner-dash-card>
            </div>
            <!-- Box: asset value - wind -->
            <div class="col-12 col-md-6 col-lg-3">
                <inner-dash-card
                    :title="$t('reports.actual_turnover')"
                    icon="far fa-analytics"
                >
                    <template slot="default">{{
                        h(stats.current.total_actual_revenue, stats.currency)
                    }}</template>
                    <template slot="footer"
                        ><i class="far fa-calendar"></i>
                        {{
                            $t("reports.actual_data_for_Y", {
                                Y: stats.current.actual_year,
                            })
                        }}
                    </template>
                </inner-dash-card>
            </div>
            <!-- Box: asset value - solar -->
            <div class="col-12 col-md-6 col-lg-3">
                <inner-dash-card
                    :title="$t('reports.budget_turnover')"
                    icon="far fa-chart-line"
                >
                    <template slot="default">{{
                        h(stats.current.total_planned_revenue, stats.currency)
                    }}</template>
                    <template slot="footer"
                        ><i class="far fa-calendar"></i>
                        {{
                            $t("reports.planned_data_for_Y", {
                                Y: stats.current.planned_year,
                            })
                        }}
                    </template>
                </inner-dash-card>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="btn-group mb-3" role="group">
                    <base-button
                        @click="showKWH = false"
                        type="primary"
                        size="sm"
                        :outline="true"
                        :class="{ active: !showKWH }"
                        >{{ $t("reports.turnover") }}</base-button
                    >
                    <base-button
                        type="primary"
                        @click="showKWH = true"
                        size="sm"
                        :outline="true"
                        :class="{ active: showKWH }"
                        >{{ $t("reports.kWh") }}</base-button
                    >
                </div>
            </div>
            <div class="col-12">
                <div style="display: block" v-if="!showKWH">
                    <apexchart
                        v-if="true"
                        height="400px"
                        :options="{
                            chart: {
                                width: '100%',
                                type: 'bar',
                                stacked: false,
                            },
                            dataLabels: {
                                enabled: true,
                                formatter: function (value, opt) {
                                    return h(value);
                                },
                            },
                            yaxis: {
                                labels: {
                                    formatter: function (value) {
                                        return h(value);
                                    },
                                },
                            },
                            xaxis: {
                                categories: stats.graph.categories,
                            },
                        }"
                        :series="[
                            {
                                name: $t('inv_stats.actual_revenue'),
                                data: stats.graph.total_actual_revenue,
                            },
                            {
                                name: $t('inv_stats.planned_revenue'),
                                data: stats.graph.total_planned_revenue,
                            },
                        ]"
                    ></apexchart>
                </div>
                <!-- 2nd graph -->
                <div style="display: block" v-else>
                    <apexchart
                        v-if="true"
                        height="400px"
                        :options="{
                            chart: {
                                width: '100%',
                                type: 'bar',
                                stacked: false,
                            },
                            dataLabels: {
                                enabled: true,
                                formatter: function (value, opt) {
                                    return hn(value);
                                },
                            },
                            yaxis: {
                                labels: {
                                    formatter: function (value) {
                                        return hn(value);
                                    },
                                },
                            },
                            xaxis: {
                                categories: stats.graph.categories,
                            },
                        }"
                        :series="[
                            {
                                name: $t('inv_stats.actual_production_kwh'),
                                data: stats.graph.total_actual_production_kwh,
                            },
                            {
                                name: $t('inv_stats.planned_production_kwh'),
                                data: stats.graph.total_planned_production_kwh,
                            },
                        ]"
                    ></apexchart>
                </div>
            </div>
            <!-- .col-12 -->
        </div>
        <!-- .row -->
        <div class="row mt-4">
            <div class="col-12 col-md-6 col-lg-4">
                <h-inner-dash-card
                    :title="
                        $t('reports.contracts_missing_budget_Y', {
                            Y: stats.current.planned_year,
                        })
                    "
                    :value="
                        $n(
                            perc(
                                stats.contracts.count_no_budget,
                                parseInt(stats.contracts.count_no_budget) +
                                    parseInt(stats.contracts.count_has_budget)
                            )
                        ) + '%'
                    "
                />
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <h-inner-dash-card
                    :title="
                        $t('reports.contracts_without_budget_Y', {
                            Y: stats.current.planned_year,
                        })
                    "
                    :value="stats.contracts.count_no_budget"
                />
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <h-inner-dash-card
                    :title="
                        $t('reports.contracts_with_budget_Y', {
                            Y: stats.current.planned_year,
                        })
                    "
                    :value="stats.contracts.count_has_budget"
                />
            </div>
        </div>
    </div>
</template>

<style>
</style>