<script>
import { cloneDeep, pick } from "lodash";
import CurrencyService from "@/services/CurrencyService"; 

export default {
    name: "VCurrencyForm",
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            },
        },
    },
    data() {
        return {
            currency: {
                id: null,
                code: null,
                rate: null,
            },
            editing: false,
            loading: false,
            errors: null             
        };
    },
    created() {        
        if (!this.context.empty) {
            this.editing = true;
            this.currency = pick(this.context, ['id', 'code', 'rate']);
        }
    },
    methods: {
        async saveForm() {
            try {
                const r = await CurrencyService.putCurrency(this.currency);                 
                this.$emit('close-refresh');                 
            } catch (e) {                
                this.errors = e.response.data.errors; 
            }
        }
    },
};
</script>

<template>
    <modal
        size="lg"
        :show="true"
        @close="$emit('close')"
        class="currency-modal"
    >
        <template slot="header">{{$t('currency.edit_rate')}}</template>
        <template slot="default">            

            <FormulateForm v-model="currency"
            @submit="saveForm"
            :errors="errors">
                <div class="fields">
                    <div class="equal-element">
                        {{ $n(100, { style: "currency", currency: currency.code, currencyDisplay: "code" }) }}
                    </div>
                    <div class="equal-element">=</div>
                    <FormulateInput
                        type="currency"
                        currency="DKK"
                        :label="$t('currency.rate')"
                        name="rate"
                    />
                </div>

                <div class="text-right">
                    <base-button type="secondary" 
                        :outline="true"
                        @click="$emit('close')"
                    >{{
                        $t("cancel")
                    }}</base-button>
                    <base-button type="primary" 
                        :loading="loading"
                        native-type="submit"
                    >{{ $t("save") }}</base-button>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style lang="scss">
.currency-modal {
    .fields {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;

        .equal-element {
            display: flex;
            font-size: 18px;
            font-weight: bold;
        }

        
    }
}
</style>
