<script>
import VAssetGroupForm from "@/components/Forms/VAssetGroupForm";
import AssetGroupService from "@/services/AssetGroupService";
import AssetTypeService from "@/services/AssetTypeService";
import VFilters from "@/components/Search/VFilters";

export default {
    name: "VIndex",
    components: {
        VAssetGroupForm,
        VFilters,
    },
    data() {
        return {
            assetGroups: [],
            assetTypes: {},
            loading: false,
            meta: {},
            editing: false,
            editContext: { empty: true },
        };
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CONTRACT_MGMT);
        this.apiSearchFilter = { name: "" };
        this.apiFilterView = "0";
        this.$apiEnableWatcher();
        await this.fetchData();
        await this.fetchTypes();
    },
    methods: {
        async fetchTypes() {
            try {
                this.loading = true;
                const r = await AssetTypeService.dropdown();
                this.assetTypes = {
                    ...{ 0: this.$t("assetGroups.all") },
                    ...r.data.data,
                };
            } catch (e) {}

            this.loading = false;
        },

        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                const r = await AssetGroupService.GET(params);
                this.assetGroups = r.data.data;
                this.meta = r.data.meta;
            } catch (e) {
                console.log(e);
            }

            this.loading = false;
        },
        /**
         * Create new
         */
        setContext(ctx) {
            this.editContext = ctx;
            this.editing = true;
        },
        /**
         *
         */
        closeSave() {
            this.editing = false;
            this.fetchData();
        },
    },
};
</script>


<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag>
                <base-button
                    v-if="ACL_RW"
                    type="secondary"
                    size="sm"
                    @click="setContext({ empty: true })"
                    >{{ $t("create_new") }}</base-button
                >
            </bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-filters
                        v-if="!loading"
                        :enable-filters="false"
                        :filter-views="assetTypes"
                        :filter-view="apiFilterView"
                        @filter-view-change="$handleFilterView($event)"
                    >
                        <template slot="search">
                            <FormulateInput
                                v-model="apiSearchFilter.name"
                                type="fsearch"
                                :placeholder="$t('assetGroups.search')"
                            />
                        </template>
                    </v-filters>

                    <card body-classes="px-0">
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="assetGroups.length">
                            <div class="table-responsive">
                                <v-table class="table table-mg table-hover">
                                    <v-thead>
                                        <v-tr>
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="name"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("assetGroups.name")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="AssetTypes.name"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "assetGroups.asset_type"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th class="text-right"></v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr
                                            v-for="ag in assetGroups"
                                            :key="ag.id"
                                        >
                                            <v-td>
                                                {{ ag.name }}
                                            </v-td>
                                            <v-td>
                                                {{ ag.asset_type.name }}
                                            </v-td>
                                            <v-td
                                                class="text-right"
                                                v-if="ACL_RW"
                                            >
                                                <i
                                                    class="far fa-pencil fa-lg m-click"
                                                    @click="setContext(ag)"
                                                ></i>
                                            </v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                            <div class="paginator">
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                        </template>

                        <div class="px-mg" v-else>
                            <div class="alert alert-warning">
                                {{ $t("table_no_data_found") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals">
            <v-asset-group-form
                v-if="editing"
                :context="editContext"
                @close="editing = false"
                @close-refresh="closeSave"
            />
        </portal>
    </div>
</template>