<script>
import EcService from "@/services/EcService";
import AssetTypeService from "@/services/AssetTypeService";
import VAssetTypeForm from "@/components/Forms/VAssetTypeForm";

export default {
    name: "VProducts",
    components: {
        VAssetTypeForm,
    },
    data() {
        return {
            loading: false,
            types: [],
            editing: false,
            editContext: null,
        };
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CONTRACT_MGMT);
        this.loading = true;
        await this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                const r = await AssetTypeService.GET();
                this.types = r.data.data;
            } catch (e) {}
            this.loading = false;
        },
        /**
         * Create new
         */
        setContext(ctx) {
            this.editContext = ctx;
            this.editing = true;
        },
        /**
         *
         */
        closeSave() {
            this.editing = false;
            this.fetchData();
        },
    },
};
</script>

<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag> </bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <card body-classes="px-0">
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="types.length">
                            <div class="table-responsive">
                                <v-table class="table table-mg table-hover">
                                    <v-thead>
                                        <v-tr>
                                            <v-th>{{
                                                $t("asset_type.name")
                                            }}</v-th>
                                            <v-th class="text-center">{{
                                                $t("asset_type.symbol")
                                            }}</v-th>
                                            <v-th
                                                class="text-right"
                                                v-if="false"
                                            ></v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr
                                            v-for="type in types"
                                            :key="type.id"
                                        >
                                            <v-td>
                                                {{ type.name }}
                                            </v-td>
                                            <v-td class="text-center">
                                                <i
                                                    class="far"
                                                    :class="type.icon"
                                                ></i>
                                            </v-td>

                                            <v-td
                                                class="text-right"
                                                v-if="false"
                                            >
                                                <i
                                                    class="far fa-pencil fa-lg m-click"
                                                    @click="setContext(type)"
                                                ></i>
                                            </v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                        </template>

                        <div class="px-mg" v-else>
                            <div class="alert alert-warning">
                                {{ $t("table_no_data_found") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals">
            <v-asset-type-form
                v-if="editing"
                :context="editContext"
                :ec_products="ec_products"
                @close="editing = false"
                @close-refresh="closeSave"
            />
        </portal>
    </div>
</template>

<style>
</style>
