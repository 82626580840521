var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-contract-index"},[_c('base-header',{staticClass:"pb-1",attrs:{"type":""}},[_c('bread-bag')],1),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row card-wrapper"},[(_vm.loading)?_c('v-loader',{attrs:{"loading":_vm.loading}}):(_vm.grouped)?_vm._l((_vm.grouped),function(contracts,termination_month,index){return _c('div',{key:termination_month,staticClass:"col-12"},[_c('card',{attrs:{"body-classes":"px-0"}},[_c('div',[_c('h3',{staticClass:"px-4 mb-4"},[_vm._v(" "+_vm._s(_vm.$t( "contracts.possible_terminations_in_month_MM_YYYY" ))+": "+_vm._s(_vm.$d( new Date(termination_month), "longMonthOnly" ))+" ")]),_c('div',{staticClass:"table-responsive"},[_c('v-table',{staticClass:"table table-sm table-mg"},[_c('v-thead',[_c('v-tr',[_c('v-th',[_vm._v(_vm._s(_vm.$t("contracts.customer")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t("contracts.contract_id")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t( "contracts.contract_asset_type" )))]),_c('v-th',[_vm._v(_vm._s(_vm.$t( "contracts.contract_type" )))]),_c('v-th',[_vm._v(_vm._s(_vm.$t( "contracts.next_possible_termination_date" )))])],1)],1),_c('v-tbody',_vm._l((contracts),function(c){return _c('v-tr',{key:c.id},[_c('v-td',{staticStyle:{"width":"40%"}},[_c('router-link',{attrs:{"to":{
                                                        name:
                                                            'CustomersTabContracts',
                                                        params: {
                                                            customer_id:
                                                                c.customer
                                                                    .id,
                                                        },
                                                    }}},[_vm._v(_vm._s(c.customer.name))])],1),_c('v-td',[_vm._v(_vm._s(c.contract_id)+" "),_c('div',{staticClass:"small text-muted"},[_vm._v(" "+_vm._s(_vm.$t( "contracts.rev_short" ))+": "+_vm._s(c.contract_version)+" ")])]),_c('v-td',[_c('i',{staticClass:"far",class:("" + (c.asset_type_icon))})]),_c('v-td',[_vm._v(_vm._s(c.contract_type_text))]),_c('v-td',[_vm._v(" "+_vm._s(_vm.$d( new Date( c.next_termination_date ), "short" ))+" ")]),(false)?_c('v-td',[_vm._v(_vm._s(c))]):_vm._e()],1)}),1)],1)],1)])])],1)}):_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"alert alert-warning alert-outline ml-4 mr-4"},[_vm._v(" "+_vm._s(_vm.$t("contracts.no_contracts_found"))+" ")])])],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }