<script>
import NetPriceService from "@/services/NetPriceService";
import VNetPriceIndexForm from "@/components/Forms/VNetPriceIndexForm";

export default {
    name: "VIndex",
    components: {
        VNetPriceIndexForm,
    },
    data() {
        return {
            indices: [],
            loading: false,
            meta: {},
            editing: false,
            editContext: { empty: true },
        };
    },
    created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CONTRACT_MGMT);
        this.apiPaginator.sort = "year";
        this.apiPaginator.direction = "desc";
        this.$apiEnableWatcher();
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                const r = await NetPriceService.getNetPrices(params);
                this.indices = r.data.data;
                this.meta = r.data.meta;
            } catch (e) {
                console.log(e);
            }

            this.loading = false;
        },
        /**
         * Create new
         */
        setContext(ctx) {
            this.editContext = ctx;
            this.editing = true;
        },
        /**
         *
         */
        closeSave() {
            this.editing = false;
            this.fetchData();
        },
    },
};
</script>

<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag>
                <base-button
                    v-if="ACL_RW"
                    type="secondary"
                    size="sm"
                    @click="setContext({ empty: true })"
                    >{{ $t("create_new") }}</base-button
                >
            </bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <card body-classes="px-0">
                        <div class="px-4 mt-2 mb-4">
                            {{$t('indices.lead_in_text')}}
                        </div>
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="indices.length">
                            <div class="table-responsive">
                                <v-table class="table table-mg table-hover">
                                    <v-thead>
                                        <v-tr>
                                            <v-th class="text-center"
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="year"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("indices.year")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="created"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("indices.created")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="user_id"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("indices.created_by")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th class="text-right"
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="price_index"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "indices.price_index"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >

                                            <v-th class="text-right" v-if="ACL_RW"></v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr
                                            v-for="idx in indices"
                                            :key="idx.id"
                                        >
                                            <v-td class="text-center">{{
                                                idx.year
                                            }}</v-td>

                                            <v-td>{{
                                                $d(
                                                    new Date(idx.created),
                                                    "long"
                                                )
                                            }}</v-td>
                                            <v-td>{{ idx.user.name }}</v-td>
                                            <v-td class="text-right">{{
                                                $n(idx.price_index, "decimal")
                                            }}</v-td>

                                            <v-td class="text-right" v-if="ACL_RW">
                                                <i
                                                    class="far fa-pencil fa-lg m-click"
                                                    @click="setContext(idx)"
                                                ></i>
                                            </v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                            <div class="paginator">
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                        </template>

                        <div class="px-mg" v-else>
                            <div class="alert alert-warning">
                                {{ $t("table_no_data_found") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals">
            <v-net-price-index-form
                v-if="editing"
                :context="editContext"
                @close="editing = false"
                @close-refresh="closeSave"
            />
        </portal>
    </div>
</template>
