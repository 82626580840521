<script>
import CurrencyService from "@/services/CurrencyService";
import VCurrencyForm from "@/components/Forms/VCurrencyForm";
import VFilters from "@/components/Search/VFilters";
import QueueService from "@/services/QueueService";

export default {
    name: "VIndex",
    components: {
        VCurrencyForm,
        VFilters,
    },
    data() {
        return {
            job_id: null,
            jobLoading: false,
            loading: false,
            currencies: [],
            meta: {},
            editing: false,
            editContext: null,
            filterViews: {
                null: this.$t("all"),
                active: this.$t("active"),
                inactive: this.$t("inactive"),
            },
        };
    },
    created() {
        this.ACL_RW = this.$aclCan(this.$ACL.CONTRACT_MGMT);
        this.apiFilterView = "active";
        this.apiSearchFilter = { code: "" };
        this.$apiEnableWatcher();
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                let params = this.$buildParams();
                this.loading = true;
                const r = await CurrencyService.getCurrencies(params);
                this.currencies = r.data.data;
                this.meta = r.data.meta;
            } catch (e) {
                console.log(e);
            }

            this.loading = false;
        },

        /**
         * Create new currency
         */
        setContext(ctx) {
            this.editContext = ctx;
            this.editing = true;
        },
        /**
         *
         */
        closeSave() {
            this.editing = false;
            this.fetchData();
        },
        /**
         * Start queue import job
         */
        async startImport() {
            try {
                this.jobLoading = true;
                const r = await QueueService.createTask("SyncCurrencies");
                this.job_id = r.data.data.job_id;
            } catch (e) {
                this.jobLoading = false;
                this.$swal({
                    icon: "error",
                    text: this.$t("queue.error_during_task_creation"),
                });
            }
        },
        /**
         * Finish queue import job
         */
        doneImporting(payload) {
            this.jobLoading = false;
            this.job_id = null;
            let msg = "";

            if (payload.result.length) {
                msg = this.$t("currencies.new_currencies_imported");
            } else {
                msg = this.$t("currencies.no_new_currencies_imported");
            }

            this.$swal({
                icon: "info",
                text: msg,
            });
        },
    },
};
</script>

<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag>
                <base-button v-if="ACL_RW" type="secondary" size="sm" @click="startImport">{{
                    $t("currencies.import_from_economic")
                }}</base-button>
            </bread-bag>
        </base-header>

        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-filters
                        :enable-filters="false"
                        :filter-views="filterViews"
                        :filter-view="apiFilterView"
                        @filter-view-change="$handleFilterView($event)"
                        v-model="apiSearchFilter.code"
                        :search="true"
                        :search-placeholder="$t('currency.search_code')"
                    >
                    </v-filters>

                    <card body-classes="px-0">
                        <v-loader :loading="loading" v-if="loading" />

                        <template v-else-if="currencies.length">
                            <div class="table-responsive">
                                <v-table class="table table-mg table-hover">
                                    <v-thead>
                                        <v-tr>
                                            <v-th>
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    name="code"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("currencies.name")
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th
                                                ><v-sorter
                                                    :paginator="apiPaginator"
                                                    name="modified"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t(
                                                            "currencies.modified"
                                                        )
                                                    }}</v-sorter
                                                ></v-th
                                            >
                                            <v-th class="text-right">
                                                <v-sorter
                                                    :paginator="apiPaginator"
                                                    name="rate"
                                                    @sort="
                                                        $handleSorting($event)
                                                    "
                                                    >{{
                                                        $t("currencies.rate")
                                                    }}</v-sorter
                                                ></v-th
                                            >

                                            <v-th
                                                class="text-right"
                                                v-if="ACL_RW"
                                            ></v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr
                                            v-for="c in currencies"
                                            :key="c.id"
                                        >
                                            <v-td>{{ c.code }}</v-td>
                                            <v-td>{{
                                                $d(new Date(c.modified), "long")
                                            }}</v-td>
                                            <v-td class="text-right">
                                                <template v-if="c.rate">
                                                    {{
                                                        $n(c.rate, {
                                                            style: "currency",
                                                            currency: "DKK",
                                                            currencyDisplay:
                                                                "code",
                                                        })
                                                    }}
                                                </template>
                                                <template v-else>
                                                    ---
                                                </template>
                                            </v-td>

                                            <v-td
                                                class="text-right"
                                                v-if="ACL_RW"
                                            >
                                                <i
                                                    class="far fa-pencil fa-lg m-click"
                                                    @click="setContext(c)"
                                                ></i>
                                            </v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                            <div class="paginator">
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                        </template>

                        <div class="px-mg" v-else>
                            <div class="alert alert-warning">
                                {{ $t("table_no_data_found") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>

        <portal to="modals">
            <v-currency-form
                v-if="editing"
                :context="editContext"
                @close="editing = false"
                @close-refresh="closeSave"
            />
        </portal>
        <v-queue
            :job_id="job_id"
            :loading="jobLoading"
            :name="$t('currencies.doing_currency_import')"
            @task-ready="doneImporting"
        />
    </div>
</template>