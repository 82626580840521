<script>
import { cloneDeep, pick } from "lodash";
import NetPriceService from "@/services/NetPriceService";
export default {
    name: "VNetPriceIndexForm",
    props: {
        context: {
            type: Object,
            default: () => { 
                empty: true;
            },
        },
    },
    data() {
        return {
            editing: false,
            loading: false,
            errors: null,
            index: {
                id: null,
                price_index: null,                
                year: null,
            },
        };
    },
    created() {
        if (!this.context.empty) {
            this.editing = true;
            this.index = pick(this.context, [
                "id",
                "price_index",
                "year",                
            ]);
        }        
    },
    methods: {
        async saveForm() {
            try {
                if (this.editing) {
                    const r = await NetPriceService.putNetPrice(this.index);                 
                } else {
                    const r = await NetPriceService.postNetPrice(this.index);
                }

                this.$emit('close-refresh');                 
            } catch (e) {                
                this.errors = e.response.data.errors; 
            }
        }
    }
};
</script>
<template>
    <modal
        size="lg"
        :show="true"
        @close="$emit('close')"
        class="currency-modal"
    >
        <template slot="header">
            <template v-if="editing">
                {{ $t("indices.edit_index") }}
            </template>
            <template v-else>
                {{ $t("indices.create_index") }}
            </template>
        </template>
        <template slot="default">
            <FormulateForm v-model="index"
            @submit="saveForm"
            :errors="errors">
                <div class="row">
                    <div class="col">
                        <FormulateInput
                            name="price_index"
                            type="decimal"
                            :label="$t('indices.price_index')"
                            :validation-name="$t('indices.price_index')"
                            validation="required"
                        />
                    </div>

                    <div class="col-5">
                        <FormulateInput
                            name="year"
                            type="text"
                            :placeholder="$t('indices.year_placeholder')"
                            :label="$t('indices.year')"
                            :validation-name="$t('indices.year')"
                            validation="required|number|between:2000,2999"

                            />
                    </div>
                </div>
                <div class="text-right mt-4">
                    <base-button type="secondary" 
                        :outline="true"
                        @click="$emit('close')"
                    >{{
                        $t("cancel")
                    }}</base-button>
                    <base-button type="primary" 
                        :loading="loading"
                        native-type="submit"
                    >{{ $t("save") }}</base-button>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>